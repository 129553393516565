import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Link, Toggle, Tooltip } from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import useTracking from '@/shared/hooks/useTracking';
import { CloseIcon, ContractCallIcon, KnifeIcon } from '@/shared/icons/large';
import { decimalRegex } from '@/shared/utils/regex';
import ShieldIcon from './SwapCard/icons/Shield';
import useSettingsStore, { selectDepositMode } from '../../hooks/useSettingsStore';
import useSudo from '../../hooks/useSudo';
import { SwapEvents, type SwapTrackEvents } from '../../types/track';

const Bolt = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="bolt (24px)">
      <path
        id="Vector"
        d="M10.75 13.4639H6.75L13.25 4.96387V10.9639H17.25L10.75 19.4639V13.4639Z"
        fill="#FF33AF"
        fillOpacity="0.4"
        stroke="#FF33AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const BOOST_MIN = 0.05;
const BOOST_MAX = 50;
const SUDO_BOOST_MIN = -BOOST_MAX;

export default function SettingsModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const track = useTracking<SwapTrackEvents>();
  const {
    toggleBoostEnabled,
    boostEnabled,
    setDepositMode,
    fillOrKillEnabled,
    toggleFillOrKillEnabled,
    slippageTolerancePercent,
    setSlippageTolerancePercent,
    swapDeadlineMinutes,
    setSwapDeadlineMinutes,
  } = useSettingsStore();
  const depositMode = useSettingsStore(selectDepositMode);
  const [slippageToleranceString, setSlippageToleranceString] = useState('');
  const [swapDeadlineString, setSwapDeadlineString] = useState('');
  const isSudo = useSudo();

  // set inputs to values from store on open
  useEffect(() => {
    setSlippageToleranceString(String(slippageTolerancePercent));
    setSwapDeadlineString(String(swapDeadlineMinutes));
  }, [isOpen]);

  const onSlippageToleranceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSudo || decimalRegex(2).test(e.target.value)) {
      setSlippageToleranceString(e.target.value);
    }
  };
  const onSlippageToleranceInputBlur = () => {
    const min = isSudo ? SUDO_BOOST_MIN : BOOST_MIN;
    let validated = Number(slippageToleranceString);
    if (!validated || validated < min) validated = min;
    if (validated > BOOST_MAX) validated = BOOST_MAX;

    setSlippageToleranceString(String(validated));
    setSlippageTolerancePercent(validated);
  };

  const onSwapDeadlineInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (decimalRegex(0).test(e.target.value)) {
      setSwapDeadlineString(e.target.value);
    }
  };
  const onSwapDeadlineInputBlur = () => {
    let validated = Number(swapDeadlineString);
    if (!validated || validated < 1) validated = 1;
    if (validated > 45) validated = 45;

    setSwapDeadlineString(String(validated));
    setSwapDeadlineMinutes(validated);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* backdrop */}
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 z-20 flex w-full items-center justify-center font-aeonikRegular text-white backdrop-blur-[5px]">
            <div className="flex h-full w-full items-center justify-center p-4 md:w-[380px]">
              <Dialog.Panel className="bg-holy-radial-gray-2-30 relative w-full rounded-md border border-cf-gray-4 bg-cf-gray-1">
                <div className="flex flex-col gap-y-5 p-6">
                  <div className="flex items-center">
                    <div className="cf-gray-gradient font-aeonikMedium text-20">Settings</div>
                    <button
                      data-testid="close-settings"
                      onClick={onClose}
                      type="button"
                      className="ml-auto flex h-6 w-6 items-center justify-center rounded-full text-cf-light-2 outline-none transition hover:bg-cf-gray-5 "
                    >
                      <CloseIcon className="transition hover:text-white" />
                    </button>
                  </div>
                  <div className="border-b border-cf-gray-3-5" />
                  <div className={classNames('flex flex-col gap-y-2')}>
                    <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
                      <div className="flex items-center gap-x-1">
                        <ShieldIcon className="text-cf-blue-2" />
                        Slippage Protection
                      </div>
                      <Toggle
                        data-testid="slippage-toggle"
                        value={fillOrKillEnabled}
                        onToggle={toggleFillOrKillEnabled}
                      />
                    </div>
                    <span className="text-12 text-cf-light-2">
                      Your deposit will be refunded if the price changes unfavourable above this
                      percentage.{' '}
                      <Link
                        href="https://docs.chainflip.io/swapping/swapping-basics#minimum-accepted-price-slippage-protection"
                        target="_blank"
                        className="underline"
                      >
                        Learn more
                      </Link>
                    </span>
                    <div className="flex flex-col gap-y-2">
                      <div className="flex flex-row items-center justify-between font-aeonikMedium">
                        <div className="flex flex-row gap-1 text-12 text-cf-light-2">
                          <span>Slippage tolerance</span>
                          <QuestionMarkTooltip content="Maximum accepted difference between quote price and swap price" />
                        </div>
                        <div className="flex flex-row items-center gap-1">
                          <div
                            className={classNames(
                              'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 text-cf-light-3',
                              'transition focus-within:bg-cf-gray-4 focus-within:text-cf-white hover:bg-cf-gray-4 hover:text-cf-white',
                            )}
                          >
                            <input
                              inputMode="decimal"
                              className="w-0 grow bg-transparent py-1 text-right outline-none"
                              value={slippageToleranceString}
                              onChange={onSlippageToleranceInputChange}
                              onBlur={onSlippageToleranceInputBlur}
                            />
                            <span className="text-cf-light-2">%</span>
                          </div>
                          {isSudo && (
                            <button
                              data-testid="sudo-set-max-slippage"
                              type="button"
                              className="text-cf-red-2 hover:text-cf-red-1"
                              onClick={() => {
                                setSlippageToleranceString(SUDO_BOOST_MIN.toString());
                                setSlippageTolerancePercent(SUDO_BOOST_MIN);
                              }}
                            >
                              <KnifeIcon />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between font-aeonikMedium">
                        <div className="flex flex-row gap-1 text-12 text-cf-light-2">
                          <span>Swap deadline</span>
                          <QuestionMarkTooltip content="Time until deposit is refunded if price exceeds slippage tolerance" />
                        </div>
                        <div
                          className={classNames(
                            'flex w-[56px] flex-row items-center gap-1 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 pr-2 text-12 text-cf-light-3',
                            'transition focus-within:bg-cf-gray-4 focus-within:text-cf-white hover:bg-cf-gray-4 hover:text-cf-white',
                          )}
                        >
                          <input
                            data-testid="swap-deadline-input"
                            inputMode="numeric"
                            className="w-0 grow bg-transparent py-1 text-right outline-none"
                            value={swapDeadlineString}
                            onChange={onSwapDeadlineInputChange}
                            onBlur={onSwapDeadlineInputBlur}
                          />
                          <span className="text-cf-light-2">min</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-cf-gray-3-5" />
                  <div className="flex flex-col gap-y-1">
                    <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
                      <div className="flex items-center gap-x-1">
                        <Bolt />
                        Boost (Bitcoin only)
                      </div>
                      <Toggle value={boostEnabled} onToggle={toggleBoostEnabled} />
                    </div>
                    <span className="text-12 text-cf-light-2">
                      Boost speeds up swap times by reducing deposit confirmation times.{' '}
                      <Link
                        href="https://docs.chainflip.io/swapping/intro-to-boost"
                        target="_blank"
                        className="underline"
                      >
                        Learn more
                      </Link>
                    </span>
                  </div>
                  <div className="border-b border-cf-gray-3-5" />
                  <div className="flex flex-col gap-y-1">
                    <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
                      <div className="flex items-center gap-x-1">
                        <ContractCallIcon className="text-cf-light-2" />
                        Smart Contract Deposits (EVM only)
                      </div>
                      <Tooltip
                        content="Not available when slippage protection is enabled"
                        disabled={!fillOrKillEnabled}
                      >
                        <Toggle
                          disabled={fillOrKillEnabled}
                          value={depositMode === 'contract'}
                          onToggle={() => {
                            const newMode = depositMode === 'contract' ? 'channel' : 'contract';
                            track(SwapEvents.SelectDepositMode, {
                              props: {
                                depositMode: newMode,
                                isRecommended: newMode === 'channel',
                              },
                            });
                            setDepositMode(newMode);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <span className="text-12 text-cf-light-2">
                      Initiate swaps without a deposit channel. No deposit fee, requires token
                      approval.{' '}
                      <Link
                        href="https://docs.chainflip.io/concepts/swaps-amm/deposit-channels-and-brokers"
                        target="_blank"
                        className="underline"
                      >
                        Learn more
                      </Link>
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
