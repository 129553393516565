import { useEffect, useMemo, useState } from 'react';
import { useWindowEventListener } from '@/shared/hooks';

type Payload<T> = {
  dispatcher: string;
  newValue: T;
};

declare global {
  interface WindowEventMap {
    'local-storage-update': CustomEvent<Payload<unknown>>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useLocalStorage<T>(key: string, parse: (value: any) => T) {
  const [value, setValue] = useState<T>(() => {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(`cf.${key}`);
      return parse(item && JSON.parse(item));
    }

    return null as never;
  });

  const dispatcher = useMemo(() => {
    try {
      return crypto.randomUUID();
    } catch {
      return Math.random().toString();
    }
  }, []);

  useWindowEventListener('local-storage-update', (e) => {
    if (e.detail.dispatcher === dispatcher) return;
    setValue((e as CustomEvent<Payload<T>>).detail.newValue);
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const save = (newValue: T) => {
    setValue(newValue);
    const newValueString = JSON.stringify(newValue);
    window.dispatchEvent(
      new CustomEvent<Payload<T>>('local-storage-update', {
        detail: {
          dispatcher,
          newValue,
        },
      }),
    );
    window.localStorage.setItem(`cf.${key}`, newValueString);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return isClient ? ([value, save] as const) : ([null, () => {}] as const);
}
