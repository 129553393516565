import { useRef } from 'react';
import classNames from 'classnames';
import { Button } from '@/shared/components';
import { useWindowEventListener } from '@/shared/hooks';
import { CloseIcon } from '@/shared/icons/small';
import { getChainflipToken } from '@/shared/utils';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useSwapRequestStore from '../../../hooks/useSwapRequestStore';

const logo = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9556_53831)">
      <g filter="url(#filter0_i_9556_53831)">
        <rect y="0.0649414" width="32.0688" height="32.0688" rx="16.0344" fill="#2C2D2C" />
        <rect
          y="0.0649414"
          width="32.0688"
          height="32.0688"
          rx="16.0344"
          fill="url(#paint0_radial_9556_53831)"
          fillOpacity="0.15"
        />
      </g>
      <g opacity="0.5" filter="url(#filter1_f_9556_53831)">
        <path
          d="M9.90364 19.8902C10.0158 19.7781 10.17 19.7126 10.3335 19.7126H25.1627C25.4337 19.7126 25.5692 20.0397 25.3777 20.2312L22.4482 23.1607C22.3361 23.2728 22.1819 23.3382 22.0184 23.3382H7.18915C6.91817 23.3382 6.78268 23.0112 6.97423 22.8196L9.90364 19.8902Z"
          fill="url(#paint1_linear_9556_53831)"
        />
        <path
          d="M9.90364 8.95317C10.0204 8.84104 10.1746 8.77563 10.3335 8.77563H25.1627C25.4337 8.77563 25.5692 9.10268 25.3777 9.29424L22.4482 12.2236C22.3361 12.3358 22.1819 12.4012 22.0184 12.4012H7.18915C6.91817 12.4012 6.78268 12.0741 6.97423 11.8826L9.90364 8.95317Z"
          fill="url(#paint2_linear_9556_53831)"
        />
        <path
          d="M22.4482 14.386C22.3361 14.2739 22.1819 14.2085 22.0184 14.2085H7.18915C6.91817 14.2085 6.78268 14.5355 6.97423 14.7271L9.90364 17.6565C10.0158 17.7686 10.17 17.834 10.3335 17.834H25.1627C25.4337 17.834 25.5692 17.507 25.3777 17.3154L22.4482 14.386Z"
          fill="url(#paint3_linear_9556_53831)"
        />
      </g>
      <g filter="url(#filter2_f_9556_53831)">
        <path
          d="M7.51759 21.3481C7.67237 21.1933 7.8852 21.103 8.11092 21.103H28.5807C28.9548 21.103 29.1418 21.5545 28.8774 21.8189L24.8337 25.8625C24.679 26.0173 24.4661 26.1076 24.2404 26.1076H3.7706C3.39654 26.1076 3.20951 25.6562 3.47393 25.3918L7.51759 21.3481Z"
          fill="url(#paint4_linear_9556_53831)"
        />
        <path
          d="M7.51783 6.25117C7.67907 6.09639 7.89189 6.0061 8.11116 6.0061H28.581C28.955 6.0061 29.1421 6.45755 28.8776 6.72197L24.834 10.7656C24.6792 10.9204 24.4664 11.0107 24.2407 11.0107H3.77084C3.39679 11.0107 3.20976 10.5592 3.47418 10.2948L7.51783 6.25117Z"
          fill="url(#paint5_linear_9556_53831)"
        />
        <path
          d="M24.8337 13.7504C24.679 13.5957 24.4661 13.5054 24.2404 13.5054H3.7706C3.39654 13.5054 3.20951 13.9568 3.47393 14.2212L7.51759 18.2649C7.67237 18.4197 7.8852 18.51 8.11092 18.51H28.5807C28.9548 18.51 29.1418 18.0585 28.8774 17.7941L24.8337 13.7504Z"
          fill="url(#paint6_linear_9556_53831)"
        />
      </g>
      <path
        d="M10.2303 20.1023C10.2866 20.046 10.3647 20.0126 10.448 20.0126H25.2772C25.2784 20.0126 25.2792 20.0127 25.2798 20.0128C25.2802 20.0133 25.2806 20.014 25.281 20.015C25.2814 20.0159 25.2816 20.0167 25.2817 20.0173C25.2813 20.0177 25.2808 20.0184 25.28 20.0191L22.3506 22.9485C22.2943 23.0049 22.2162 23.0382 22.1329 23.0382H7.30365C7.30254 23.0382 7.30168 23.0382 7.30105 23.0381C7.30071 23.0376 7.3003 23.0369 7.29989 23.0359C7.2995 23.0349 7.29928 23.0342 7.29917 23.0336C7.29956 23.0331 7.30012 23.0325 7.30087 23.0317L7.09112 22.822L7.30087 23.0317L10.2303 20.1023Z"
        fill="url(#paint7_linear_9556_53831)"
        stroke="url(#paint8_linear_9556_53831)"
        strokeWidth="0.6"
      />
      <path
        d="M10.0181 8.95317C10.1349 8.84104 10.2891 8.77563 10.448 8.77563H25.2772C25.5482 8.77563 25.6837 9.10268 25.4922 9.29424L22.5627 12.2236C22.4506 12.3358 22.2964 12.4012 22.1329 12.4012H7.30365C7.03267 12.4012 6.89718 12.0741 7.08874 11.8826L10.0181 8.95317Z"
        fill="url(#paint9_linear_9556_53831)"
      />
      <path
        d="M7.30087 12.0947L10.2278 9.1678C10.2921 9.10693 10.3723 9.07563 10.448 9.07563H25.2772C25.2784 9.07563 25.2792 9.07568 25.2798 9.07575C25.2802 9.07624 25.2806 9.07696 25.281 9.07794C25.2814 9.0789 25.2816 9.07968 25.2817 9.08026C25.2813 9.08074 25.2808 9.08136 25.28 9.08211L22.3506 12.0115C22.2943 12.0679 22.2162 12.1012 22.1329 12.1012H7.30365C7.30254 12.1012 7.30168 12.1011 7.30105 12.1011C7.30072 12.1006 7.3003 12.0999 7.29989 12.0989C7.2995 12.0979 7.29928 12.0971 7.29917 12.0966C7.29956 12.0961 7.30012 12.0955 7.30087 12.0947Z"
        stroke="url(#paint10_linear_9556_53831)"
        strokeOpacity="0.5"
        strokeWidth="0.6"
      />
      <path
        d="M22.1329 14.5085C22.2162 14.5085 22.2943 14.5418 22.3506 14.5982L25.28 17.5276C25.2808 17.5283 25.2813 17.5289 25.2817 17.5294C25.2816 17.53 25.2814 17.5308 25.281 17.5317C25.2806 17.5327 25.2802 17.5334 25.2798 17.5339C25.2792 17.534 25.2784 17.534 25.2772 17.534H10.448C10.3647 17.534 10.2866 17.5007 10.2303 17.4444L7.30087 14.515C7.30012 14.5142 7.29956 14.5136 7.29917 14.5131C7.29928 14.5125 7.2995 14.5118 7.29989 14.5108C7.3003 14.5098 7.30071 14.5091 7.30105 14.5086C7.30168 14.5085 7.30254 14.5085 7.30365 14.5085H22.1329Z"
        fill="url(#paint11_linear_9556_53831)"
        stroke="url(#paint12_linear_9556_53831)"
        strokeWidth="0.6"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_9556_53831"
        x="0"
        y="0.0649414"
        width="33.969"
        height="33.969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.90017" dy="1.90017" />
        <feGaussianBlur stdDeviation="6.17555" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9556_53831" />
      </filter>
      <filter
        id="filter1_f_9556_53831"
        x="-7.16918"
        y="-5.27807"
        width="46.6902"
        height="42.6704"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="7.02685" result="effect1_foregroundBlur_9556_53831" />
      </filter>
      <filter
        id="filter2_f_9556_53831"
        x="-34.2766"
        y="-31.6206"
        width="100.905"
        height="95.3558"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="18.8134" result="effect1_foregroundBlur_9556_53831" />
      </filter>
      <radialGradient
        id="paint0_radial_9556_53831"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0344 0.0649414) rotate(90) scale(20.5062)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_9556_53831"
        x1="23.7461"
        y1="7.0253"
        x2="13.4831"
        y2="26.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9556_53831"
        x1="19.2586"
        y1="4.6828"
        x2="8.99552"
        y2="24.3406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9556_53831"
        x1="21.4881"
        y1="5.84599"
        x2="11.225"
        y2="25.5038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9556_53831"
        x1="26.6253"
        y1="3.58984"
        x2="12.4585"
        y2="30.7248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_9556_53831"
        x1="20.431"
        y1="0.356501"
        x2="6.2643"
        y2="27.4915"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_9556_53831"
        x1="23.5083"
        y1="1.96205"
        x2="9.34157"
        y2="29.0971"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_9556_53831"
        x1="23.8606"
        y1="7.0253"
        x2="13.5976"
        y2="26.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_9556_53831"
        x1="6.99902"
        y1="21.5254"
        x2="25.5819"
        y2="21.5254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DF59F3" />
        <stop offset="1" stopColor="#91B4E0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_9556_53831"
        x1="19.3731"
        y1="4.6828"
        x2="9.11003"
        y2="24.3406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_9556_53831"
        x1="6.99902"
        y1="10.5884"
        x2="25.5819"
        y2="10.5884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4B5E8" />
        <stop offset="0.229137" stopColor="#67C9D7" />
        <stop offset="1" stopColor="#17F3C6" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_9556_53831"
        x1="21.6026"
        y1="5.84599"
        x2="11.3395"
        y2="25.5038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_9556_53831"
        x1="6.99902"
        y1="16.0213"
        x2="25.5819"
        y2="16.0213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B293E9" />
        <stop offset="1" stopColor="#6EC6D8" />
      </linearGradient>
      <clipPath id="clip0_9556_53831">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const useSolanaBannerStorage = () =>
  useLocalStorage('solana-banner', (value) => (typeof value === 'boolean' ? value : true));

const SolanaBanner = () => {
  const [showBanner, setShowBanner] = useSolanaBannerStorage();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const circleContainsPoint = (box: DOMRect | undefined, x: number, y: number) => {
    if (!box) return false;

    const circleX = box.x + box.width / 2;
    const circleY = box.y + box.height / 2;
    const radius = box.width / 2;

    return Math.sqrt((circleX - x) ** 2 + (circleY - y) ** 2) <= radius;
  };

  useWindowEventListener('click', (e) => {
    if (circleContainsPoint(buttonRef.current?.getBoundingClientRect(), e.clientX, e.clientY)) {
      setShowBanner(false);
    }
  });

  const setDestToken = useSwapRequestStore((store) => store.setDestToken);
  const setSrcToken = useSwapRequestStore((store) => store.setSrcToken);

  return (
    <div
      suppressHydrationWarning
      className={classNames(
        'absolute -top-12 bottom-0 hidden h-12 w-full items-center rounded-t-md border border-b-0 border-cf-gray-4 px-2',
        showBanner && '[@media(min-height:960px)]:flex',
      )}
      style={{
        background:
          'linear-gradient(75deg, rgba(100, 70, 218, 0.00) 32.83%, rgba(100, 70, 218, 0.10) 99.01%), radial-gradient(317.48% 523.63% at -24.8% 265.62%, #6446DA 0%, rgba(70, 147, 218, 0.02) 33%, rgba(100, 70, 218, 0.00) 100%), #161616',
      }}
    >
      <button
        ref={buttonRef}
        type="button"
        className="absolute -right-2 -top-2 z-10 flex h-5 w-5 items-center justify-center rounded-full border border-cf-gray-4 bg-cf-gray-3-5 text-cf-light-3"
        onClick={() => setShowBanner(false)}
      >
        <CloseIcon />
      </button>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-x-2">
          {logo}
          <span className="font-aeonikMedium text-14 text-white">
            Solana is now available on Chainflip!
          </span>
        </div>
        <Button
          size="small"
          type="secondary-standard"
          onClick={() => {
            setSrcToken(getChainflipToken('Eth'));
            setDestToken(getChainflipToken('Sol'));
          }}
        >
          Swap SOL
        </Button>
      </div>
    </div>
  );
};

export default SolanaBanner;
